import styled from 'styled-components';

const Card = styled.div`
  display: block;
  margin: 10px 0 20px;
  padding: ${({padding}) => padding || '10px'};
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
`;

export default Card;
