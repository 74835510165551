import styled from 'styled-components';

const CenterContent = styled.div`
  display: ${({displayFlex}) => displayFlex ? 'flex' : 'block'};
  flex-direction: ${({flexDirection}) => flexDirection};
  width: 100%;
  max-width: ${({maxWidth}) => maxWidth || '944px'};
  padding: ${({padding}) => padding || '0'};
  margin: 0 auto;
`;

export default CenterContent;
