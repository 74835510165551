import React from 'react';
import styled from 'styled-components';
import CenterContent from '../../../components/Page/CenterContent';
import { Row, Col } from '../../../components/Grid';
import brazilMap from './brazil-map.png';
import Pin from './Pin';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 32px 16px;
`;

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 64px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }
`;

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 400;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.2;
`;

const Map = styled.div`
  position:relative;
  width: 467px;
  border-radius: 8px;

  img {
    position:relative;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    width: 340px;
    min-width: 300px;
    height: 342px;
  }
`;

const pins = [
  {
    top: 'calc(50% + 88px)',
    left: 'calc(50% + 58px)',
    state: 'São Paulo',
    projects: [
      {
        name: 'CPFL Paulista',
        potency: '10MW',
      },
      {
        name: 'CPFL Pirantininga',
        potency: '7MW',
      },
      {
        name: 'EDP SP',
        potency: '2MW',
      },
      {
        name: 'Elektro',
        potency: '4.3MW',
      },
      {
        name: 'Eletropaulo',
        potency: '11MW',
      },
      {
        name: 'Sul Sudeste',
        potency: '10.8MW',
      },
    ],
  },
  {
    top: 'calc(50% + 47px)',
    left: 'calc(50% + 101px)',
    state: 'Minas Gerais',
    projects: [
      {
        name: 'Cemig-D',
        potency: '15MW',
      },
    ],
  },
  {
    top: 'calc(50% + 82px)',
    left: 'calc(50% + 122px)',
    state: 'Rio de Janeiro',
    projects: [
      {
        name: 'ENEL RJ',
        potency: '18MW',
      },
      {
        name: 'Light',
        potency: '15MW',
      },
      {
        name: 'CERES',
        potency: '1MW',
      },
    ],
  },
  {
    top: 'calc(50% + -39px)',
    left: 'calc(50% + 139px)',
    state: 'Bahia',
    projects: [
      {
        name: 'Coelba',
        potency: '6MW',
      },
    ],
  },
  {
    top: 'calc(50% + -64px)',
    left: 'calc(50% + 52px)',
    state: 'Tocantins',
    projects: [
      {
        name: 'ETO',
        potency: '1MW',
      },
    ],
  },
  {
    top: 'calc(50% + -55px)',
    left: 'calc(50% + -117px)',
    state: 'Roraima',
    projects: [
      {
        name: 'CERON',
        potency: '5MW',
      },
    ],
  },
];

const FieldSection = () => {
  return (
    <Wrapper>
      <CenterContent>
        <SectionTitle>Área de Atuação</SectionTitle>
        <Row flexWrap="wrap">
          <Col minWidth="320px" centerHorizontally>
            <H2>A cobertura mais diversa do mercado</H2>
            <Text>Estamos em constante expansão para dar cada vez mais poder na mão dos nosso clientes e parceiros.</Text>
          </Col>
          <Col centerHorizontally style={{textAlign: 'center'}}>
            <Map>
              <img src={brazilMap} alt="Mapa do Brasil" />
              { pins.map(pin => (<Pin key={pin.state} {...pin} />)) }
            </Map>
          </Col>
        </Row>
      </CenterContent>
    </Wrapper>
  );
}

export default FieldSection;
