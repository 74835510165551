import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import missions from './missions';
import missionTasks from './mission-tasks';
import classes from './classes';

export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  missions,
  classes,
  missionTasks,
});
