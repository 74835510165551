import styled from 'styled-components';
import Button from '../../components/Button';
import Card from '../../components/Card';

export const Wrapper = styled.section`
display: flex;
width: 100%;
min-height: 676px;
padding: 64px 16px;
`;

export const FormCard = styled(Card)`
padding: 24px;
min-height: 200px;
margin: ${({isMobile}) => isMobile ? '10px -16px 20px' : '30px auto'};
width: 500px;

${({isMobile}) => isMobile && `
  width: 100vw;
  overflow: hidden;
`};
`;

export const Tabs = styled.div`
margin-bottom: 32px;
`;

export const TabButton = styled.span`
padding: 4px 0;
margin-right: 48px;
cursor: pointer;
font-size: 1rem;
font-weight: ${({isSelected}) => isSelected ? '700' : '400'};
border-bottom: ${({isSelected}) => isSelected ? '4px solid #2883EF' : '0'};
color: #1E2036;
transition: all 150ms ease-out;
`;

export const FormWrapper = styled.div`
input {
  margin-bottom: 16px;
}
`;

export const SubmitButton = styled(Button)`
width: 100%;
margin-top: 4px;
justify-content: center;
font-weight: 900;
`;
