import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button ,{ WhiteSecondaryButton } from '../Button';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 16px 72px;
  background: linear-gradient(#3244da, #3ba0da);
  color: #fff;
`;

const Title = styled.h1`
  position: relative;
  font-size: 2.4rem;
  text-align: center;
  margin: 0 0 32px;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -20px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#fff 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: .95rem;
`;

const Ctas = styled.div`
  margin-top: 16px;
  text-align: center;

  ${Button} {
    margin: 8px;
    width: 260px;
    justify-content: center;
  }
`;

const LastAppealSection = () => {
  return (
    <Wrapper>
      <Title>Venha conosco você também</Title>
      <Text>Faça uma simulação ou entre em contato para darmos o primeiro passo</Text>
      <Ctas>
        <WhiteSecondaryButton as={Link} to="/contato" title="Fale conosco">Contato</WhiteSecondaryButton>
        <Button as={HashLink} smooth to="/#simulacao" title="Ir para Simulação">Simulação</Button>
      </Ctas>
    </Wrapper>
  );
}

export default LastAppealSection;
