import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../redux/store'
import ScrollToTop from './ScrollToTop';
import HomePage from '../containers/HomePage';
import LoginPage from '../containers/LoginPage';
import ContactPage from '../containers/ContactPage';
import AboutUsPage from '../containers/AboutUsPage';
import CustomerHomePage from '../containers/CustomerPortal';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  let loggedUser = localStorage.getItem('loggedUser');

  if(loggedUser && loggedUser !== 'undefined') {
    loggedUser = JSON.parse(loggedUser);

    if(loggedUser.token.token) {
      if(roles && roles.length) {
        const hasRole = roles.reduce((acc, role) => (
          acc || loggedUser.user.roles.includes(role)
        ), false);

        return (
          <Route {...rest} render={(props) => (
            hasRole
              ? <Component {...props} />
              : <Redirect to='/' />
            )} />
        );
      }

      return (
        <Route {...rest} render={(props) => (
            <Component {...props} />
          )} />
      );
    }
  }

  return (
    <Route {...rest} render={() => (
        <Redirect to='/' />
      )} />
  );
}

class Routes extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/contato" component={ContactPage} />
            <Route exact path="/quem-somos" component={AboutUsPage} />

            {/* Missions */}
            {/* <PrivateRoute exact path='/missions' component={MissionsPage} />
            <PrivateRoute exact path="/mission/:id" component={MissionPage} />

            {/* User */}
            {/* <PrivateRoute exact path='/edit-profile' component={EditProfilePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignUpPage} /> */} */}

            {/* Customer Portal */}
            <Route exact path="/portal-do-cliente" component={CustomerHomePage} />

            {/* Admin */}
            {/* <PrivateRoute
              exact
              path='/admin/create-user'
              roles={['admin','hr_manager']}
              component={CreateUserPage} /> */}

            {/* Fallback */}
            <PrivateRoute component={HomePage} />
          </Switch>
        </ScrollToTop>
      </ConnectedRouter>
    );
  }
}

export default Routes;
