import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import ResultChart from './ResultChart';
import { Message } from '../../../components/Message';

const Wrapper = styled.div`
  margin: 0;

  img {
    width: 100%;
    margin-top: 16px;
  }

  ${Button} {
    width: 100%;
    justify-content: center;
  }
`;

const BackLink = styled.a`
  font-size: .9rem;
  margin-bottom: 16px;
  display: block;
  text-align: right;
`;

const InfosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 16px 0;
  @media (max-width: 768px) {
    padding: 8px 0 0;
  }
`;

const Info = styled.div`
  flex: 1;
  min-width: 200px;
  border-left: 2px solid #d4ed72;
  margin-bottom: 0;
  padding: 0 8px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const Label = styled.span`
  display: block;
  font-size: .9rem;
  font-size: 14px;
  color: #4D4D4D;
  margin-right: 8px;

  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const Value = styled.span`
  display: block;
  font-size: 1rem;
  font-size: 14px;
  font-weight: 900;

  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const Result = ({result, currentExpenditure, onBackToForm}) => {
  // eslint-disable-next-line no-script-url
  const href = 'javascript: void(0)'

  return (
    <Wrapper>
      <BackLink href={href} onClick={onBackToForm}>&lt; Fazer outra simulação</BackLink>
      <InfosWrapper>
        <Info>
          <Label>Concessionária:</Label>
          <Value>{result.dealership}</Value>
        </Info>
        <Info>
          <Label>Potência necessária:</Label>
          <Value>*{result.needed_power.toFixed(2)}kWp</Value>
        </Info>
        <Info>
          <Label>Cotas necessárias:</Label>
          <Value>*{result.number_of_quotas} cotas</Value>
        </Info>
      </InfosWrapper>
      <ResultChart {...result} currentExpenditure={currentExpenditure} />
      <Message>* Valores aproximados. Para valores precisos, entrar em contato.</Message>
      <Button as={Link} title="Contato" to="/contato">Entrar em contato</Button>
    </Wrapper>
  );
}

export default Result;
