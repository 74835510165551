import React, { Component } from 'react';
import Page from '../../components/Page';
import CenterContent from '../../components/Page/CenterContent';
import TimelineSection from './TimelineSection';
import ValuesSection from './ValuesSection';
import TeamSection from './TeamSection';
import LastAppealSection from '../../components/LastAppealSection';

class AboutUsPage extends Component {
  render() {
    return (
      <Page pageTitle="Ideias Energia - Quem Somos">
        <CenterContent padding="32px 16px 16px">
          <TimelineSection />
          <ValuesSection />
          <TeamSection />
        </CenterContent>
        <LastAppealSection />
      </Page>
    );
  }
}

export default AboutUsPage;
