import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Routes from './routes';
import './App.css';
import { requestLoginSuccess } from './redux/actions/user';

class App extends Component {
  componentWillMount() {
    let loggedUser = localStorage.getItem('loggedUser');

    if(loggedUser && loggedUser !== 'undefined') {
      loggedUser = JSON.parse(loggedUser);
      this.props.requestLoginSuccess(loggedUser);
    }
  }

  render() {
    return (
      <Routes />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestLoginSuccess,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
