import {
  createMissionTask,
  deleteMissionTask,
  updateMissionTask,
} from '../../services/MissionTasks';

export const actionTypes = {
  RESET_FETCHING_STATUS: 'RESET_FETCHING_STATUS',
  RESET_ACTION_FETCHING_STATUS: 'RESET_ACTION_FETCHING_STATUS',
  REQUEST_CREATE_MISSION_TASK: 'REQUEST_CREATE_MISSION_TASK',
  REQUEST_CREATE_MISSION_TASK_SUCCESS: 'REQUEST_CREATE_MISSION_TASK_SUCCESS',
  REQUEST_CREATE_MISSION_TASK_ERROR: 'REQUEST_CREATE_MISSION_TASK_ERROR',
  REQUEST_DELETE_MISSION_TASK: 'REQUEST_DELETE_MISSION_TASK',
  REQUEST_DELETE_MISSION_TASK_SUCCESS: 'REQUEST_DELETE_MISSION_TASK_SUCCESS',
  REQUEST_DELETE_MISSION_TASK_ERROR: 'REQUEST_DELETE_MISSION_TASK_ERROR',
  REQUEST_EDIT_MISSION_TASK: 'REQUEST_EDIT_MISSION_TASK',
  REQUEST_EDIT_MISSION_TASK_SUCCESS: 'REQUEST_EDIT_MISSION_TASK_SUCCESS',
  REQUEST_EDIT_MISSION_TASK_ERROR: 'REQUEST_EDIT_MISSION_TASK_ERROR',
};

export const resetFetchingStatus = () => ({
  type: actionTypes.RESET_FETCHING_STATUS
});

export const resetActionFetchingStatus = () => ({
  type: actionTypes.RESET_ACTION_FETCHING_STATUS
});

export const requestCreateMissionTask = () => ({
  type: actionTypes.REQUEST_DELETE_MISSION_TASK
});

export const requestCreateMissionTaskSuccess = () => ({
  type: actionTypes.REQUEST_CREATE_MISSION_TASK_SUCCESS
});

export const requestCreateMissionTaskError = error => ({
  type: actionTypes.REQUEST_CREATE_MISSION_TASK_ERROR,
  error
});

export const fetchCreateMissionTask = (data) => {
  return (dispatch, getState) => {
    dispatch(requestCreateMissionTask());

    const userToken = getState().user.token.token;

    createMissionTask(userToken, data).then(() => {
      dispatch(requestCreateMissionTaskSuccess());
    })
    .catch(error => {
      dispatch(requestCreateMissionTaskError(error));
    });
  };
};

export const requestDeleteMissionTask = () => ({
  type: actionTypes.REQUEST_DELETE_MISSION_TASK,
});

export const requestDeleteMissionTaskSuccess = () => ({
  type: actionTypes.REQUEST_DELETE_MISSION_TASK_SUCCESS,
});

export const requestDeleteMissionTaskError = error => ({
  type: actionTypes.REQUEST_DELETE_MISSION_TASK_ERROR,
  error
});

export const fetchDeleteMissionTask = missionTaskId => {
  return (dispatch, getState) => {
    dispatch(requestDeleteMissionTask(missionTaskId));

    const userToken = getState().user.token.token;

    return deleteMissionTask(userToken, missionTaskId).then(() => {
      dispatch(requestDeleteMissionTaskSuccess(missionTaskId));
      return true;
    })
    .catch(error => {
      dispatch(requestDeleteMissionTaskError(missionTaskId, error));
    });
  };
};

export const requestEditMissionTask = () => ({
  type: actionTypes.REQUEST_EDIT_MISSION_TASK
});

export const requestEditMissionTaskSuccess = () => ({
  type: actionTypes.REQUEST_EDIT_MISSION_TASK_SUCCESS
});

export const requestEditMissionTaskError = error => ({
  type: actionTypes.REQUEST_EDIT_MISSION_TASK_ERROR,
  error
});

export const fetchEditMissionTask = (newData, missionTaskId) => {
  return (dispatch, getState) => {
    dispatch(requestEditMissionTask(missionTaskId));

    const userToken = getState().user.token.token;

    return updateMissionTask(userToken, missionTaskId, newData).then(() => {
      dispatch(requestEditMissionTaskSuccess(missionTaskId));
      return true;
    })
    .catch(error => {
      dispatch(requestEditMissionTaskError(missionTaskId, error));
    });
  };
};
