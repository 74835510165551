import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import CenterContent from '../../components/Page/CenterContent';
import Button from '../../components/Button';
import { MessageSuccess, MessageError } from '../../components/Message';

// const API_URL = 'http://localhost:3333/api';
// const API_URL = 'http://localhost/api';
const API_URL = 'https://ideiasenergia.com/api';

const FormWrapper = styled.section`
  margin: 32px 0 64px;
  padding: 16px;

  input {
    margin-bottom: 16px;
  }

  textarea {
    height: 200px;
  }
`;

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 40px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 4px;
  justify-content: center;
  font-weight: 900;
`;

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }
  sendContact = (data) => {
    return axios.post(
      `${API_URL}/contact`,
      { ...data },
      { headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }},
    );
  };

  handleSubmit = (values) => {
    console.log(values);
    this.setState({ isLoading: true });

    this.sendContact(values)
      .then(response => {
        this.setState({
          hasSent: true,
          hasError: false,
        })
      }).catch(error => {
        this.setState({
          hasSent: false,
          hasError: error.response.data.error
        });
      }).finally(() =>{
        this.setState({ isLoading: false });
      })
  };

  render() {
    return (
      <Page pageTitle="Ideias Energia - Contato">
        <CenterContent maxWidth="784px">
          <FormWrapper>
            <SectionTitle>Contato</SectionTitle>
            <Formik
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
            >
              {() => (
                <Form>
                  <Field type="text" name="name" placeholder="Nome" />
                  <Field type="email" name="email" placeholder="Email" />
                  <Field type="phone" name="phone" placeholder="Telefone/Celular" />
                  <Field type="text" name="cep" placeholder="CEP" />
                  <Field type="text" name="place_type" placeholder="Tipo de local (empresarial ou residencial)" />
                  <Field component="textarea" rows="5" name="message" placeholder="Mensagem" />
                  <SubmitButton type="submit" disabled={this.state.isLoading}>Enviar</SubmitButton>
                </Form>
              )}
            </Formik>
            {this.state.isLoading && (
              <div style={{margin:'32px', textAlign:'center'}}>
                <Loader />
              </div>
            )}
            {this.state.hasSent && <MessageSuccess>Mensagem enviada com sucesso! Em breve retornaremos seu contato.</MessageSuccess>}
            {this.state.hasError && <MessageError>Ocorreu um erro ao enviar. Tente novamente mais tarde ou fale conosco pelo número: +55 (11) 5181-4360</MessageError>}
          </FormWrapper>
        </CenterContent>
      </Page>
    );
  }
}

export default ContactPage;
