import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import PageContent from './PageContent';
import Header from '../Header';
import Footer from './Footer';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideHeader: false,
    }
  }

  handleScrolling = () => {
    const myWindow = window;
    let currentPosition = myWindow.scrollTop;
    let up = false;
    let newScroll;

    myWindow.scroll(function () {
        newScroll = myWindow.scrollTop;
        if (newScroll > currentPosition && !up) {
          this.setState({
            hideHeader: true
          });
          up = !up;
        } else if(newScroll < currentPosition && up) {
          this.setState({
            hideHeader: false
          });
          up = !up;
        }
        currentPosition = newScroll;
    });
  }

  render() {
    const {children, pageTitle} = this.props;

    return (
      <PageWrapper onScroll={this.handleScrolling}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle || 'Ideias Energia'}</title>
        </Helmet>
        <Header isHidden={this.state.hideHeader}/>
        <PageContent>
          {children}
        </PageContent>
        <Footer />
      </PageWrapper>
    );
  }
}

export default Page;
