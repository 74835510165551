import { actionTypes } from '../actions/user';

const initialState = {
  isLoading: false,
  hasError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasSignedUp: null,
        ...action.data,
      }
    case actionTypes.REQUEST_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: action.error,
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
      }
    case actionTypes.REQUEST_SIGNUP:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.REQUEST_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasSignedUp: true,
      }
    case actionTypes.REQUEST_SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: action.error,
        hasSignedUp: false,
      }
    case actionTypes.REQUEST_CREATE_USER:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.REQUEST_CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdUser: {
          ...action.data,
          hasError: false,
        },
      }
    case actionTypes.REQUEST_CREATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        createdUser: {
          hasError: true,
          error: action.error,
        },
      }
    case actionTypes.NEW_USER_UPDATE:
      return {
        ...state,
        updateError: null,
        hasUpdated: null,
      }
    case actionTypes.REQUEST_UPDATE_USER:
      return {
        ...state,
        updateError: null,
        hasUpdated: null,
        isLoading: true,
      }
    case actionTypes.REQUEST_UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasUpdated: true,
        updateError: false,
        user: {
          ...state.user,
          ...action.data,
        },
      }
    case actionTypes.REQUEST_UPDATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        hasUpdated: true,
        updateError: action.error,
      }
    default:
      return state
  }
}
