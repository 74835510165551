import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../../components/Page/SectionTitle';
import { Row, Col } from '../../components/Grid';

const Wrapper = styled.section`
  margin-bottom: 32px;
`;

const Person = styled(Col)`
  display: flex;
  /* min-width: 304px; */
  /* max-width: 304px; */
  min-width: 320px;
  max-width: none;
  flex-basis: 50%;
  margin-bottom: 40px;
`;

// const Photo = styled.img`
//   flex-shrink: 0;
//   width: 144px;
//   height: 192px;
//   background-color: #ddd;
//   border-radius: 4px;
// `;

const Info = styled.div`
  margin: 0 16px;
`;

const Name = styled.h2`
  margin: 0 0 2px;
  font-size: 1rem;
  line-height: 19px;
`;

const Position = styled.h3`
  margin: 0 0 16px;
  font-size: .95rem;
  font-weight: 300;
  line-height: 19px;
`;

const Description = styled.p`
  margin: 0;
  font-size: .8rem;
  line-height: 1rem;
`;

const TeamSection = () => {
  return (
    <Wrapper>
      <SectionTitle>Nosso time</SectionTitle>
      <Row flexWrap="wrap" justifyContent="center">
        <Person>
          {/* <Photo src="https://loremflickr.com/144/192/person?random=1" alt="Foto Mauricio Filgueiras" /> */}
          <Info>
            <Name>Mauricio Filgueiras</Name>
            <Position>Sócio/CEO</Position>
            <Description>
              Brasileiro<br/>
              Engenheiro Civil<br/>
              Especialista no desenvolvimento de projetos industriais e infraestrutura
            </Description>
          </Info>
        </Person>
        <Person>
          {/* <Photo src="https://loremflickr.com/144/192/person?random=3" alt="Foto Andrea Filgueiras" /> */}
          <Info>
            <Name>Andrea Filgueiras</Name>
            <Position>COO</Position>
            <Description>
              Brasileira<br/>
              Economista<br/>
              Especialista Planejamento e operação de produtos de Tecnologia
            </Description>
          </Info>
        </Person>
        <Person>
          {/* <Photo src="https://loremflickr.com/144/192/person?random=4" alt="Foto Pedro Camargo" /> */}
          <Info>
            <Name>Pedro Camargo</Name>
            <Position>VP Marketing e Vendas</Position>
            <Description>
              Brasileiro<br/>
              Administrador<br/>
              Especialista em vendas de serviços de Engenharia
            </Description>
          </Info>
        </Person>
        <Person>
          {/* <Photo src="https://loremflickr.com/144/192/person?random=5" alt="Foto Rodrigo Ribas" /> */}
          <Info>
            <Name>Rodrigo Ribas</Name>
            <Position>VP Engenharia</Position>
            <Description>
              Brasileiro<br/>
              Engenheiro Eletricista<br/>
              Especialista em projetos Solares
            </Description>
          </Info>
        </Person>
      </Row>
    </Wrapper>
  );
}

export default TeamSection;
