import React from 'react';
import uuid from 'uuid/v4'
import Page from '../../components/Page';
import { Row, Col } from '../../components/Grid';
import Button from '../../components/Button';
import { HashLink } from 'react-router-hash-link';

import {
  Toolbar,
  PageLimit,
  ActionsWrapper,
  MeterCard,
  Bold,
  Step,
  StepText,
  Ball,
  LastWrapper,
  Title,
  Ctas,
} from './styles'

const model = (color) => ({
  id: uuid(),
  name: 'sítio Iperó',
  station: 'Elektro - SP',
  cotas: 10,
  color,
})

const redContent = [model('#EA0404')]
const yellowContent = Array.from(Array(2).keys()).map(() => model('#FEB019'))
const greenContent = Array.from(Array(4).keys()).map(() => model('#92D487'))
const greyContent = [model('#C4C4C4')]

const CustomerPortal = () => {
  return (
    <Page pageTitle="Portal do Cliente - Ideias Energia" maxWidth="900px">
      <Toolbar>
        <PageLimit>
          <h2>Meus Medidores</h2>

          <ActionsWrapper>
            <Button bgColor='#2196F3'>Minhas Faturas</Button>

            &nbsp;&nbsp;&nbsp;

            <Button>Adicionar Medidor</Button>
          </ActionsWrapper>
        </PageLimit>
      </Toolbar>

      <PageLimit>
        <Row>
          <Col centerHorizontally>
            {redContent.map((meter) => (
              <MeterCard key={meter.id} color={meter.color}>
                <p><b>{meter.name}</b></p>
                <p>{meter.station}</p>
                <p>{`${meter.cotas} Cotas`}</p>
              </MeterCard>
            ))}
          </Col>

          <Col centerHorizontally>
            {yellowContent.map((meter) => (
              <MeterCard key={meter.id} color={meter.color}>
                <p><b>{meter.name}</b></p>
                <p>{meter.station}</p>
                <p>{`${meter.cotas} Cotas`}</p>
              </MeterCard>
            ))}
          </Col>

          <Col centerHorizontally>
            {greenContent.map((meter) => (
              <MeterCard key={meter.id} color={meter.color}>
                <p><b>{meter.name}</b></p>
                <p>{meter.station}</p>
                <p>{`${meter.cotas} Cotas`}</p>
              </MeterCard>
            ))}
          </Col>

          <Col centerHorizontally>
            {greyContent.map((meter) => (
              <MeterCard key={meter.id} color={meter.color}>
                <p><b>{meter.name}</b></p>
                <p>{meter.station}</p>
                <p>{`${meter.cotas} Cotas`}</p>
              </MeterCard>
            ))}
          </Col>
        </Row>

        <br /><br /><br />
      </PageLimit>

      <Toolbar>
        <PageLimit>
          <h2>Meus Contratos</h2>

          <ActionsWrapper>
            <Button>Nova Compra</Button>
          </ActionsWrapper>
        </PageLimit>
      </Toolbar>

      <PageLimit>
        <br /><br /><br /><br />

        <Row>
          <Bold>CPFL <br/> Piratininga - SP</Bold>

          <Step>
            <Ball withLine />

            <StepText>Cadastro Finalizado</StepText>
          </Step>

          <Step>
            <Ball withLine />

            <StepText>Em negociação</StepText>
          </Step>

          <Step>
            <Ball withLine />

            <StepText>Obras em Andamento</StepText>
          </Step>

          <Step>
            <Ball withLine color='#D27940' />

            <StepText>Aguardando Aprovação</StepText>
          </Step>

          <Step>
            <Ball color='#C4C4C4' />

            <StepText>Interligação Concluída</StepText>
          </Step>
        </Row>

        <br /><br /><br /><br />
      </PageLimit>

      <LastWrapper>
        <Title>Está com duvidas? Entre em contato conosco!</Title>
        <Ctas>
          <Button as={HashLink} smooth to="/#simulacao" title="Ir para Simulação">Contato</Button>
        </Ctas>
      </LastWrapper>
    </Page>
  )
}

export default CustomerPortal
