import { actionTypes } from '../actions/mission-tasks';

const initialState = {
  hasLoaded: false,
  isLoading: false,
  hasError: false,
  actionFetching: {
    hasLoaded: false,
    isLoading: false,
    hasError: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_FETCHING_STATUS:
      return {
        ...state,
        hasLoaded: false,
        isLoading: false,
        hasError: false,
      }
    case actionTypes.RESET_ACTION_FETCHING_STATUS:
      return {
        ...state,
        actionFetching: {
          hasLoaded: false,
          isLoading: false,
          hasError: false,
        }
      }
      case actionTypes.REQUEST_CREATE_MISSION_TASK:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            isLoading: true,
          }
        }
      case actionTypes.REQUEST_CREATE_MISSION_TASK_SUCCESS:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: false,
          }
        }
      case actionTypes.REQUEST_CREATE_MISSION_TASK_ERROR:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: action.error,
          }
        }
      case actionTypes.REQUEST_DELETE_MISSION_TASK:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            isLoading: true,
          }
        }
      case actionTypes.REQUEST_DELETE_MISSION_TASK_SUCCESS:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: false,
          }
        }
      case actionTypes.REQUEST_DELETE_MISSION_TASK_ERROR:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: action.error,
          }
        }
      case actionTypes.REQUEST_EDIT_MISSION_TASK:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            isLoading: true,
          }
        }
      case actionTypes.REQUEST_EDIT_MISSION_TASK_SUCCESS:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: false,
          }
        }
      case actionTypes.REQUEST_EDIT_MISSION_TASK_ERROR:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: action.error,
          }
        }
    default:
      return state
  }
}
