import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../../components/Page/SectionTitle';

const Wrapper = styled.section`
  margin-top: 8px;
`;

const Mark = styled.div`
  position: relative;
  display: flex;
  width: calc(50% - 20px);
  margin: -24px 8px 8px;
  padding: 16px;
  background: #fff;
  border: 1px solid rgba(40, 131, 239, 0.3);
  box-shadow: 0px 2px 4px rgba(40, 131, 239, 0.16);
  border-radius: 4px;
  transition: 150ms all ease-out;

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 16px;
    top: calc(50% - 8px);
    right: -19px;
    background-color: #fff;
    border: 2px solid rgba(40, 131, 239, 0.8);
    transition: 150ms all ease-out;
  }

  @media(min-width: 650px) {
    &:hover {
      border-width: 2px;
      margin-top: -26px;
      transform: translateX(-4px);
      box-shadow: 0px 2px 6px rgba(40, 131, 239, 0.2);

      &:before {
        width: 16px;
        height: 16px;
        right: -26px;
        background-color: rgba(40, 131, 239, 0.8);
      }
    }
  }

  @media(max-width: 650px) {
    flex-direction: row-reverse;
    width: 100%;
    margin: 4px 0;
    padding: 8px;

    &:before {
      left: -18px;
    }
  }
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 16px;
  margin: 0 0 0 16px;
  border-left: 1px solid #e6e6e6;
  font-size: 1.5rem;

  @media(max-width: 650px) {
    padding: 0 8px 0 0;
    margin: 0 8px 0 0;
    border-left: 0;
    border-right: 1px solid #e6e6e6;
    font-size: 1.2rem;
  }
`;

const Timeline = styled.div`
  position: relative;
  min-height: 120px;
  padding: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 3px);
    border-radius: 10px;
    width: 6px;
    height: 100%;
    background-color: #abcbf8;
  }

  @media(min-width: 650px) {
    ${Mark}:nth-child(2n + 1) {
      margin-left: calc(50% + 12px);
      flex-direction: row-reverse;

      &:before {
        left: -19px;
      }

      &:hover {
        transform: translateX(4px);

        &:before {
          left: -26px;
        }
      }

      ${Date} {
        padding: 0 16px 0 0;
        margin: 0 16px 0 0;
        border-left: 0;
        border-right: 1px solid #e6e6e6;
      }

      @media(max-width: 650px) {
        margin-left: 0;
        flex-direction: row-reverse;
      }
    }
  }


  @media(max-width: 650px) {
    padding: 0 0 0 20px;

    &:before {
      left: 6px;
    }
  }
`;

const FixedDate = styled.div`
  position: relative;
  display: block;
  height: 60px;
  margin: 16px;
  font-size: 1rem;
  text-align: center;

  span {
    display: inline-block;
    left: calc(50% - 30px);
    border-radius: 60px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: #abcbf8;
    color: #2883EF;
    font-weight: 900;
  }

  @media(max-width: 650px) {
    margin: 0;
    text-align: left;

    span {
      left: -31px;
      position: relative;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-top: 10px;
      font-size: .9rem;
    }
  }
`;

const Month = styled.span`
  display: block;
`;

const Year = styled.span`
  display: block;
  font-weight: 900;
`;

const Description = styled.div`
  flex: 1;

  @media(max-width: 650px) {
    font-size: .9rem;
  }
`;


const marks = [
  {
    month: 'ABR',
    year: '2012',
    description: 'A ANEEL lança a RN 482, que permite a produção de energia pelos próprios consumidores.'
  },
  {
    month: 'SET',
    year: '2015',
    description: 'Nasce a Ideias Energia, com o objetivo de entrar no mercado de energia solar para os grandes leilões de energia.'
  },
  {
    month: 'NOV',
    year: '2016',
    description: 'A ANEEL lança a RN 687, permitindo a produção remota de energia.'
  },
  {
    month: 'JAN',
    year: '2017',
    description: 'A empresa entra no mercado de geração compartilhada remota, com o objetivo de democratizar a energia limpa para todos. '
  },
  {
    month: 'MAI',
    year: '2017',
    description: 'É protocolado junto à EDP-SP o primeiro Projeto Cachoeira Paulista, o Projeto Piloto. '
  },
  {
    month: null,
    year: '2018',
    description: 'A Ideias Energia foca em desenvolver projetos em diversos estados brasileiros. Passamos de 75kw em desenvolvimento para 60Mw.'
  },
  {
    month: 'JUL',
    year: '2019',
    description: 'A Ideias Energia fecha o acordo com a UniMagna, Projeto Baronesa '
  },
  {
    month: 'SET',
    year: '2019',
    description: 'Início da implantação do Projeto Baronesa'
  },
  {
    month: 'NOV',
    year: '2019',
    description: 'Anunciada a parceria entre a Ideias Energia e o InvestSP'
  },
  {
    month: 'JAN',
    year: '2020',
    description: 'Lançamento do Sistema Operacional Ideias'
  },
  {
    month: 'JAN',
    year: '2020',
    description: 'Começo das Operações da Baronesa'
  },
];

const TimelineSection = () => {
  return (
    <Wrapper>
      <SectionTitle>Quem somos</SectionTitle>
      <Timeline>
        <FixedDate>
          <span>2012</span>
        </FixedDate>
        {marks && marks.map(({month, year, description}, index) => (
          <Mark key={index}>
            <Description>{description}</Description>
            <Date>
              <Month>{month}</Month>
              <Year>{year}</Year>
            </Date>
          </Mark>))}
        <FixedDate>
          <span>2020</span>
        </FixedDate>
      </Timeline>
    </Wrapper>
  );
}

export default TimelineSection;
