import React from 'react';
import styled from 'styled-components';
import Chart from "react-apexcharts";

const Wrapper = styled.div`
  margin-top: 32px;
  transform: translateX(-16px);
  ${({isMobile}) => isMobile && `
    width: calc(100% + 38px);
    margin: 20px 0 -40px;
  `}
`;

const formatValue = value => {
  const parts = value.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return '*R$'+parts.join(',');
};

class ResultChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMobile: window.innerWidth <= 600};

    window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 600 });
  }

  render() {
    const { plans_with_values, currentExpenditure } = this.props;
    const { isMobile } = this.state;
    const plansNames = plans_with_values.reduce((acc, plan) => {
      return [...acc, 'Plano '+plan.name];
    }, []);
    const newValues = plans_with_values.reduce((acc, plan) => {
      return [...acc, plan.new_value.toFixed(2)];
    }, []);

    const config = {
      options: {
        chart: {
          id: 'savings-by-plan-chart'
        },
        xaxis: {
          categories: plansNames
        },
        yaxis: {
          tickAmount: 1,
          max: currentExpenditure,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          },
        },
        subtitle: {
          text: 'Consumo atual(R$):',
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: true,
          style: {
            fontSize: '11px',
            color: '#666'
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value, opts) => formatValue(value),
          style: {
            fontFamily: 'fira_sans-bold, fira_sans',
            colors: ['#333'],
            fontSize: isMobile ? '11px' : '14px'
          },
          offsetY: isMobile ? -18 : -24,
          offsetX: isMobile ? -4 : 0,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.1
          }
        },
        grid: {
          show: true,
          borderColor: '#b6b6b6',
          strokeDashArray: 5,
          position: 'back',
        },
      },
      series: [
        {
          name: 'Novo valor',
          data: newValues
        }
      ]
    };

    // "id": 2,
    // "name": "Viabilidade",
    // "fidelity_years": 5,
    // "discount": 7,
    // "created_at": "2019-10-31 16:42:43",
    // "updated_at": "2019-10-31 16:42:43",
    // "quota_value": 3384935.6501008626,
    // "saving_value": -1231992836.4098275,
    // "new_value": 1232020436.4098275,
    // "saving_percentage": -44637.42160905172

    return (
      <Wrapper isMobile={isMobile}>
        <Chart
            options={config.options}
            series={config.series}
            type="bar"
            width="100%"
            height="260"
          />
      </Wrapper>
    );
  }
}

export default ResultChart;
