import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../../components/Grid';
import Button from '../../../components/Button';

const Wrapper = styled(Row)`
  @media (max-width: 880px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    border-radius: 6px;
    box-shadow: inset -23px 0 19px -11px #f4f9fe;

    ${Col} {
      min-width: 40%;
    }
  }

  @media (max-width: 650px) {
    ${Col} {
      min-width: 80%;
    }
  }
`;

const Plan = styled.div`
  margin: 16px 8px;
  background: #F4F9FE;
  border-radius: 6px;
  padding: 16px 8px 8px;
  transition: all 150ms ease-out;

  ${Button} {
    width: 100%;
    justify-content: center;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 3px 0px rgba(0,0,0,0.06);
  }
`;

const Title = styled.h4`
  position: relative;
  color: #000000;
  font-size: 20px;
  margin: 0 4px 24px;

  &:after {
    content: '';
    position: absolute;
    bottom: -11px;
    width: 100px;
    height: 2px;
    background: #2883EF;
    left: 0;
  }
`;
const Years = styled.p`
  color: #2883ef;
  font-weight: 700;
  margin: 0 4px 16px;
`;
const Description = styled.ul`
  margin: 16px 0px;
  list-style: none;
  background: rgba(255,255,255,0.9);
  padding: 8px 16px;
  border-radius: 4px;

  li {
    font-size: 14px;
    color: #4D4D4D;
    margin-bottom: 6px;

    &:before {
      content: '-';
      margin-right: 4px;
      font-weight: 900;
      color: #92d487;
      font-size: 1rem;
    }
  }
`;
const Discount = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  background: #f1fcf4;
  background: #2883ef;
  color: #fff;
  font-size: 0.8rem;

  em {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
  }
`;

const Plans = () => {
  return (
    <Wrapper>
      <Col>
        <Plan>
          <Title>Plano Ecologia</Title>
          <Years>Fidelidade 2 anos</Years>
          <Description>
            <li>Certeza de sustentabilidade</li>
            <li>Liberdade de compensação</li>
            <li>Plataforma de Gestão</li>
            <li>Previsibilidade de Gastos</li>
          </Description>
          <Discount>desconto de <em>5%</em></Discount>
          <Button as={Link} to="/contato" title="Contratar plano">Contratar</Button>
        </Plan>
      </Col>
      <Col>
        <Plan>
          <Title>Plano Viabilidade</Title>
          <Years>Fidelidade 5 anos</Years>
          <Description>
            <li>Certeza de sustentabilidade</li>
            <li>Liberdade de compensação</li>
            <li>Plataforma de Gestão</li>
            <li>Previsibilidade de Gastos</li>
            <li>Sem investimento</li>
          </Description>
          <Discount>desconto de <em>7%</em></Discount>
          <Button as={Link} to="/contato" title="Contratar plano">Contratar</Button>
        </Plan>
      </Col>
      <Col>
        <Plan>
          <Title>Plano Equitativo</Title>
          <Years>Fidelidade 10 anos</Years>
          <Description>
            <li>Certeza de sustentabilidade</li>
            <li>Liberdade de compensação</li>
            <li>Plataforma de Gestão</li>
            <li>Previsibilidade de Gastos</li>
            <li>Sem investimento</li>
            <li>Atendimento Privativo</li>
          </Description>
          <Discount>desconto de <em>10%</em></Discount>
          <Button as={Link} to="/contato" title="Contratar plano">Contratar</Button>
        </Plan>
      </Col>
      <Col>
        <Plan>
          <Title>Plano Sustentabilidade</Title>
          <Years>Fidelidade 15 anos</Years>
          <Description>
            <li>Certeza de sustentabilidade</li>
            <li>Liberdade de compensação</li>
            <li>Plataforma de Gestão</li>
            <li>Previsibilidade de Gastos</li>
            <li>Sem investimento</li>
            <li>Atendimento Privativo</li>
          </Description>
          <Discount>desconto de <em>12%</em></Discount>
          <Button as={Link} to="/contato" title="Contratar plano">Contratar</Button>
        </Plan>
      </Col>
    </Wrapper>
  );
}

export default Plans;
