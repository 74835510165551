import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import Button from '../../../components/Button';
import CenterContent from '../../../components/Page/CenterContent';
import illustrationMobile from './illustration-mobile.png';
import illustrationBgMobile from './illustration-bg-mobile.png';
import illustrationFull from './illustration-full.png';
import lightingIcon from './lightning.svg';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  min-height: 560px;
  padding: 128px 16px 16px;
  background: url(${illustrationFull}) no-repeat;
  background-position-x: calc(50% + 523px);
  background-position-y: calc(50% - 51px);
  background-size: 987px auto;

  @media (max-width: 1180px) {
    min-height: 456px;
    padding-top: 96px;
    background-size: auto 440px;
    background-position-x: calc(50% + 264px);
  }
  @media (max-width: 880px) {
    min-height: 596px;
    padding-top: 88px;
    background: url(${illustrationBgMobile}) no-repeat;
    background-size: auto;
    background-position-y: bottom;
    background-position-x: center;
  }
  @media (max-width: 714px) {
    padding-top: 64px;
  }
`;

const RightColumn = styled.div`
  width: 55%;

  ${Button} {
    margin-top: 16px;
    width: 273px;
    justify-content: center;
  }

  @media (max-width: 1180px) {
    width: 45%;

    ${Button} {
      width: 273px;
    }
  }
  @media (max-width: 880px) {
    width: 100%;
    text-align: center;

    ${Button} {
      width: 100%;
      max-width: 360px;
    }
  }
`;

const Title = styled.h1`
  position: relative;
  font-size: 3rem;
  margin: 0 0 32px;
  color: #162b48;

  @media (max-width: 1180px) {
    font-size: 2.8rem;
  }
  @media (max-width: 880px) {
    font-size: 2.5rem;
  }
`;

// const Text = styled.p`
//   font-size: 1.2rem;
//   color: #162b48;

//   @media (max-width: 1180px) {
//     font-size: 1rem;
//   }
// `;

const MainBenefits = styled.ul`
  margin-top: 24px;
  padding: 0;
  list-style: none;
  /* color: #8b95a3; */
  color: #162b48;
  line-height: 1.6;

  li {
    margin-bottom: 4px;

    &:before {
      background: url(${lightingIcon}) no-repeat center;
      display: inline-block;
      content: '';
      width: 23px;
      height: 22px;
      margin-right: 4px;
      font-weight: 900;
      color: #92d487;
      font-size: 1rem;
      background-size: auto 100%;
      margin-bottom: -5px;
    }
  }
`;

const IllustrationMobile = styled.figure`
  width: 100%;
  margin: 24px 0;
  text-align: center;

  img {
    width: 100%;
    max-width: 348px;
  }

  @media (min-width: 880px) {
    display: none;
  }
`;

const TopSection = () => {
  return (
    <Wrapper>
      <CenterContent>
        <RightColumn>
          <Title>Sua Energia Limpa sem dor de cabeça</Title>
          {/* <Text>Você cuida do seu negócio e nós cuidamos da sua energia!</Text> */}
          <MainBenefits>
            <li>Zero investimento</li>
            <li>Economia de 5% a 12% na sua fatura</li>
            <li>Sustentabilidade onde você precisar</li>
          </MainBenefits>
          <Button as={HashLink} smooth to="/#simulacao" title="Ir para Simulação">Fazer simulação</Button>
        </RightColumn>
        <IllustrationMobile>
          <img src={illustrationMobile} alt="Ilustração de fornecimento de energia" />
        </IllustrationMobile>
      </CenterContent>
    </Wrapper>
  );
}

export default TopSection;
