import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import Button from '../../../components/Button';
import CenterContent from '../../../components/Page/CenterContent';
import { Row, Col } from '../../../components/Grid';
import Card from '../../../components/Card';
import { MessageError } from '../../../components/Message';
import Loader from '../../../components/Loader';
import bgShape from './shape3.png';
import Result from './Result';
import Plans from './Plans';

// const API_URL = 'http://localhost:3333/api';
// const API_URL = 'http://localhost/api';
// const API_URL = 'http://18.189.243.25/api';
const API_URL = 'https://ideiasenergia.com/api';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  min-height: 676px;
  padding: 64px 16px;
  background: url(${bgShape}) no-repeat;
  background-position-y: 0;
  background-position-x: calc(100% + 80px);
`;

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 64px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }

  @media (max-width: 768px) {
    margin: 0 0 32px;
  }
`;

const H2 = styled.h2`
  position: relative;
  font-size: 2rem;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 32px;

  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    width: 100px;
    height: 2px;
    background: #2883EF;
    left: 0;
  }
`;

// const Text = styled.p`
//   font-size: 1rem;
//   line-height: 1.2;
// `;

const FormCard = styled(Card)`
  padding: 24px;
  min-height: 200px;
  margin: ${({isMobile}) => isMobile ? '10px -16px 20px' : '10px 0 20px'};
  ${({isMobile}) => isMobile && `
    width: 100vw;
    overflow: hidden;
  `};
`;

const Tabs = styled.div`
  margin-bottom: 32px;
`;

const TabButton = styled.span`
  padding: 4px 0;
  margin-right: 48px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${({isSelected}) => isSelected ? '700' : '400'};
  border-bottom: ${({isSelected}) => isSelected ? '4px solid #2883EF' : '0'};
  color: #1E2036;
  transition: all 150ms ease-out;
`;

const FormWrapper = styled.div`
  input {
    margin-bottom: 16px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 4px;
  justify-content: center;
  font-weight: 900;
`;

const RadioButtonGroup = styled.div`
  display: flex;
  height: 40px;
  flex-direction: row;
  border-radius: 4px;
  border: 2px solid #2883EF;
  padding: 0;
  margin: 0 0 16px;
`;

const RadioButtonWrapper = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1E2036;
  font-size: .9rem;
  transition: all 150ms ease-out;

  input {
    display: none;
  }
  label {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 33px;
    text-align: center;
    cursor: pointer;
  }

  ${({isSelected}) => isSelected && `
    background: #2883EF;
    color: #fff;
  `};
`;

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  isSelected,
  ...props
}) => {
  return (
    <RadioButtonWrapper isSelected={isSelected}>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </RadioButtonWrapper>
  );
};

class SimulationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      average_expenditure: 0,
      tensionLevel: 'low',
      placeType: 'company',
      showTensionOptions: false,
      showResults: false,
      isMobile: window.innerWidth <= 600,
    }

    window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 600 });
  }

  handleChangePlaceType = place => {
    this.setState({
      placeType: place,
    })
  }

  handleChangeTension = (tension) => {
    this.setState({
      tensionLevel: tension,
    })
  }

  handleChangeConsumption = (e) => {
    const consumption = e.target.value;

    if(consumption >= 1000) {
      this.setState({
        average_expenditure: parseInt(consumption),
        showTensionOptions: true,
      })
    } else {
      this.setState({
        average_expenditure: parseInt(consumption),
        showTensionOptions: false,
      })
    }
  }

  createSimulation = (data) => {
    return axios.post(
      `${API_URL}/simulation`,
      { ...data },
      { headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }},
    );
  };

  handleSubmit = (values) => {
    console.log(values);
    this.setState({ isLoading: true });
    const { placeType, average_expenditure } = this.state;

    this.createSimulation({
      place_type: placeType,
      average_expenditure,
      ...values,
    }).then(response => {
      this.setState({
        showResults: true,
        result: response.data,
        hasError: false,
      })
    }).catch(error => {
      this.setState({
        hasError: error.response.data.error
      });
    }).finally(() =>{
      this.setState({ isLoading: false });
    })
  };

  backToForm = () => {
    this.setState({
      showResults: false,
    })
  };

  render() {

    return (
      <Wrapper id="simulacao">
        <CenterContent>
          <SectionTitle>Sustentabilidade também no seu Bolso</SectionTitle>
          <Row>
            {(!this.state.showResults) && (<Col minWidth="300px">
              <H2>Faça uma simulação e veja como é fácil</H2>
              {/* <Text>Coloque os seus dados e veja qual o seu uso e quanto você economiza com a gente.</Text> */}
            </Col>)}

            <Col>
              <FormCard isMobile={this.state.isMobile}>
                {this.state.isLoading
                  ? <Loader />
                : (
                  !this.state.showResults
                    ?(<React.Fragment>
                      <Tabs>
                        <TabButton isSelected={this.state.placeType === 'company'} onClick={() => this.handleChangePlaceType('company')}>Empresarial</TabButton>
                        <TabButton isSelected={this.state.placeType === 'home'} onClick={() => this.handleChangePlaceType('home')}>Residencial</TabButton>
                      </Tabs>
                      <FormWrapper>
                        <Formik
                          initialValues={{
                            tension_level: this.state.tensionLevel,
                          }}
                          onSubmit={this.handleSubmit}
                          enableReinitialize={true}
                        >
                          {() => (
                            <Form>
                              <Field type="number" name="average_expenditure" placeholder="Consumo médio (em R$)" onChange={this.handleChangeConsumption} />
                              {this.state.placeType === 'company' &&
                              <div>
                                <Field type="number" name="number_of_locations" placeholder="Quantidade de localizações?" />
                                {this.state.showTensionOptions &&
                                  (<span>
                                    <RadioButtonGroup
                                      id="radioGroup"
                                      // value={values.radioGroup}
                                    >
                                      <Field
                                        component={RadioButton}
                                        name="tension_level"
                                        value="tension_level"
                                        isSelected={this.state.tensionLevel === 'low'}
                                        onClick={() => this.handleChangeTension('low')}
                                        id="low"
                                        label="Baixa tensão"
                                      />
                                      <Field
                                        component={RadioButton}
                                        name="tension_level"
                                        value="tension_level"
                                        isSelected={this.state.tensionLevel === 'middle'}
                                        onClick={() => this.handleChangeTension('middle')}
                                        id="middle"
                                        label="Média tensão"
                                      />
                                    </RadioButtonGroup>
                                    {this.state.tensionLevel === 'middle' && <Field type="number" name="tip_cons_percentage" placeholder="Qual a % de consumo na ponta?" />}
                                    {this.state.tensionLevel === 'middle' && <Field type="number" name="contracted_demand" placeholder="Demanda contradada" />}
                                  </span>)
                                }
                              </div>
                              }
                              <Field type="text" name="cep" placeholder="CEP" />
                              <Field type="email" name="email" placeholder="Email" />
                              {this.state.hasError && (<MessageError>{this.state.hasError}</MessageError>)}
                              <SubmitButton type="submit">
                                Calcular
                              </SubmitButton>
                            </Form>
                          )}
                        </Formik>
                      </FormWrapper>
                    </React.Fragment>)
                    : (<Result result={this.state.result} currentExpenditure={this.state.average_expenditure} onBackToForm={this.backToForm} />)
                )}
              </FormCard>
            </Col>

          </Row>
          {(!this.state.isLoading && this.state.showResults) && <Plans />}
        </CenterContent>
      </Wrapper>
    );
  }
}

export default SimulationSection;
