import React from 'react';
import styled from 'styled-components';
import emailIcon from './email-icon.png';
import phoneIcon from './phone-icon.png';
import locationIcon from './location-icon.png';

const Wrapper = styled.footer`
  width: 100%;
  padding: 32px 16px;
`;

const CenterContent = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 944px;
  height: inherit;
  margin: 0 auto;
  padding: 0 8px;
`;

const Column = styled.div`
  flex: 1;
  margin: 0 32px;
  padding-left: 44px;
  background-position: top left;
  font-size: .9rem;

  p {
    margin: 4px 0;
    font-weight: 300;
    font-style: italic;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin: 0 0 24px 0;
  }
`;

const EmailColumn = styled(Column)`
  background: url(${emailIcon}) no-repeat;
`;

const PhoneColumn = styled(Column)`
  background: url(${phoneIcon}) no-repeat;
`;

const AddressColumn = styled(Column)`
  background: url(${locationIcon}) no-repeat;
`;

const GroupTitle = styled.h3`
  margin: -1px 0 4px;
  font-size: .9rem;
  font-weight: 700;
`;

const Copyright = styled.p`
  width: 100%;
  margin-top: 32px;
  text-align: center;
  font-weight: 300;
  font-style: italic;
  font-size: .7rem;
  color: #555;
`;

const Footer = () => {
  return (
    <Wrapper>
      <CenterContent>
        <EmailColumn>
          <GroupTitle>Email</GroupTitle>
          <p>contato@ideiasenergia.com</p>
        </EmailColumn>
        <PhoneColumn>
          <GroupTitle>Telefone</GroupTitle>
          <p>+55 (11) 5181-4360</p>
          <p>+55 (11) 99935-1490</p>
        </PhoneColumn>
        <AddressColumn>
          <GroupTitle>Endereço</GroupTitle>
          <address>
            <p>Rua Nazaré Paulista, 294</p>
            <p>Alto de Pinheiros</p>
            <p>CEP: 04558-000</p>
            <p>São Paulo - SP - Brasil</p>
          </address>
        </AddressColumn>
      </CenterContent>
      <Copyright>Ideias Energia - Todos os direitos reservados - Copyright © - 2019</Copyright>
    </Wrapper>
  );
}

export default Footer;
