import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 32px;
  height: 28px;
  position: relative;
  margin: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: ${({color}) => color || '#2883EF'};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),&:nth-child(3) {
      top: 12px;
    }

    &:nth-child(4) {
      top: 24px;
    }

    ${({isOpen}) => isOpen && `
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    `}
  }
`;

class HamburgerIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { color } = this.props;

    return (
      <Wrapper isOpen={this.state.isOpen} color={color} onClick={this.handleClick}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Wrapper>
    );
  }
}

export default HamburgerIcon;
