import { getClasses } from '../../services/Classes';
import { normalizeById } from '../../utils/normalize';

export const actionTypes = {
  REQUEST_CLASSES: 'REQUEST_CLASSES',
  REQUEST_CLASSES_SUCCESS: 'REQUEST_CLASSES_SUCCESS',
  REQUEST_CLASSES_ERROR: 'REQUEST_CLASSES_ERROR',
};

export const requestClasses = () => ({
  type: actionTypes.REQUEST_CLASSES
});

export const requestClassesSuccess = data => ({
  type: actionTypes.REQUEST_CLASSES_SUCCESS,
  data,
});

export const requestClassesError = error => ({
  type: actionTypes.REQUEST_CLASSES_ERROR,
  error
});

export const fetchClasses = (userToken) => {
  return (dispatch, getState) => {
    dispatch(requestClasses());

    getClasses(userToken).then(({ data }) => {
      const classesById = normalizeById(data);

      dispatch(requestClassesSuccess({classesById}));
    })
    .catch(error => {
      dispatch(requestClassesError(error));
    });
  };
};
