import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from '../Button';
import DropdownMenu from './DropdownMenu';
import HamburgerIcon from './HamburgerIcon';
import ideiasLogo from '../../assets/logo.png';

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 60px;
  background-color: #fff;
  border-bottom: 2px solid #f0f1ff;
  display: ${({hideHeader}) => (hideHeader ? 'none' : 'block')};
`;

const CenterContent = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 944px;
  height: inherit;
  margin: 0 auto;
  padding: 0 12px;
`;

const LogoWrapper = styled.h1`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const RightLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Mobile = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Desktop = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLinks = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
`;

const StyledLink = ({ current, ...rest }) => <Link {...rest} />

const NavLink = styled(StyledLink)`
  margin-left: 16px;
  padding: 8px 8px;
  color: #333;
  font-size: .9rem;
  font-weight: ${({current}) => current ? '900' : 'normal'};
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownMenuOpen: false,
      hideHeader: false,
    }
  }

  render() {
    const user = {
      roles: []
    }
    const currentPath = this.props.router.location.pathname;
    // const { user } = this.props.user;
    // let loggedUser = localStorage.getItem('loggedUser');
    // let userData = null;

    // if(loggedUser && loggedUser !== 'undefined') {
    //   loggedUser = JSON.parse(loggedUser);

    //   if(loggedUser.token.token) {
    //     userData = loggedUser.user;
    //   }
    // }

    return (
      <HeaderWrapper hideHeader={this.props.isHidden}>
        <CenterContent>
          <LogoWrapper>
            <Link to="/" title="Ideias Energia">
              <Logo src={ideiasLogo} alt="Ideias Energia" />
            </Link>
          </LogoWrapper>
          <RightLinks>
            <Mobile>
              <DropdownMenu userRoles={user.roles}>
                <HamburgerIcon />
              </DropdownMenu>
            </Mobile>
            <Desktop>
              <NavLinks>
                <NavLink to="/" current={currentPath === '/'}>Home</NavLink>
                {/* <NavLink to={`/servicos`}>Serviços</NavLink> */}
                <NavLink to={`/quem-somos`} current={currentPath === '/quem-somos'}>Quem somos</NavLink>
                <NavLink to={`/contato`} current={currentPath === '/contato'}>Contato</NavLink>
              </NavLinks>
              <Button as={HashLink} smooth to="/#simulacao" title="Ir para Simulação">Fazer Simulação</Button>
            </Desktop>
          </RightLinks>
        </CenterContent>
      </HeaderWrapper>
    );
  }
}

const mapStateToProps = ({ router, user }) => ({
  router,
  user,
});

export default connect(mapStateToProps, null)(Header);
