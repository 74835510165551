import {
  getMissions,
  getMission,
  createMission,
  deleteMission,
  updateMission,
} from '../../services/Missions';
import { createAchievement, deleteAchievement } from '../../services/Achievements';
import { normalizeById, getIdsList } from '../../utils/normalize';

export const actionTypes = {
  RESET_FETCHING_STATUS: 'RESET_FETCHING_STATUS',
  RESET_ACTION_FETCHING_STATUS: 'RESET_ACTION_FETCHING_STATUS',
  REQUEST_MISSIONS: 'REQUEST_MISSIONS',
  REQUEST_MISSIONS_SUCCESS: 'REQUEST_MISSIONS_SUCCESS',
  REQUEST_MISSIONS_ERROR: 'REQUEST_MISSIONS_ERROR',
  REQUEST_MISSION: 'REQUEST_MISSION',
  REQUEST_MISSION_SUCCESS: 'REQUEST_MISSION_SUCCESS',
  REQUEST_MISSION_ERROR: 'REQUEST_MISSION_ERROR',
  REQUEST_ACHIEVEMENT: 'REQUEST_ACHIEVEMENT',
  REQUEST_ACHIEVEMENT_SUCCESS: 'REQUEST_ACHIEVEMENT_SUCCESS',
  REQUEST_ACHIEVEMENT_ERROR: 'REQUEST_ACHIEVEMENT_ERROR',
  REQUEST_DELETE_ACHIEVEMENT: 'REQUEST_DELETE_ACHIEVEMENT',
  REQUEST_DELETE_ACHIEVEMENT_SUCCESS: 'REQUEST_DELETE_ACHIEVEMENT_SUCCESS',
  REQUEST_DELETE_ACHIEVEMENT_ERROR: 'REQUEST_DELETE_ACHIEVEMENT_ERROR',
  REQUEST_CREATE_MISSION: 'REQUEST_CREATE_MISSION',
  REQUEST_CREATE_MISSION_SUCCESS: 'REQUEST_CREATE_MISSION_SUCCESS',
  REQUEST_CREATE_MISSION_ERROR: 'REQUEST_CREATE_MISSION_ERROR',
  REQUEST_DELETE_MISSION: 'REQUEST_DELETE_MISSION',
  REQUEST_DELETE_MISSION_SUCCESS: 'REQUEST_DELETE_MISSION_SUCCESS',
  REQUEST_DELETE_MISSION_ERROR: 'REQUEST_DELETE_MISSION_ERROR',
  REQUEST_EDIT_MISSION: 'REQUEST_EDIT_MISSION',
  REQUEST_EDIT_MISSION_SUCCESS: 'REQUEST_EDIT_MISSION_SUCCESS',
  REQUEST_EDIT_MISSION_ERROR: 'REQUEST_EDIT_MISSION_ERROR',
};

export const resetFetchingStatus = () => ({
  type: actionTypes.RESET_FETCHING_STATUS
});

export const resetActionFetchingStatus = () => ({
  type: actionTypes.RESET_ACTION_FETCHING_STATUS
});

export const requestMissions = () => ({
  type: actionTypes.REQUEST_MISSIONS
});

export const requestMissionsSuccess = data => ({
  type: actionTypes.REQUEST_MISSIONS_SUCCESS,
  data,
});

export const requestMissionsError = error => ({
  type: actionTypes.REQUEST_MISSIONS_ERROR,
  error
});

export const fetchMissions = () => {
  return (dispatch, getState) => {
    dispatch(requestMissions());

    const userToken = getState().user.token.token;

    getMissions(userToken).then(({ data }) => {
      const missionsIds = getIdsList(data);
      const missionsById = normalizeById(data);

      dispatch(requestMissionsSuccess({missionsIds, missionsById}));
    })
    .catch(error => {
      dispatch(requestMissionsError(error));
    });
  };
};

export const requestMission = () => ({
  type: actionTypes.REQUEST_MISSION
});

export const requestMissionSuccess = (mission, tasksById) => ({
  type: actionTypes.REQUEST_MISSION_SUCCESS,
  mission,
  tasksById,
});

export const requestMissionError = error => ({
  type: actionTypes.REQUEST_MISSION_ERROR,
  error
});

export const fetchMission = missionId => {
  return (dispatch, getState) => {
    dispatch(requestMission());

    const userToken = getState().user.token.token;

    getMission(userToken, missionId).then(({ data }) => {
      const { tasks, ...mission } = data;
      const tasksById = tasks && normalizeById(tasks);

      dispatch(requestMissionSuccess(mission, tasksById));
    })
    .catch(error => {
      dispatch(requestMissionError(error));
    });
  };
};

export const requestAchievement = taskId => ({
  type: actionTypes.REQUEST_ACHIEVEMENT,
  taskId,
});

export const requestAchievementSuccess = taskId => ({
  type: actionTypes.REQUEST_ACHIEVEMENT_SUCCESS,
  taskId,
});

export const requestAchievementError = (error, taskId) => ({
  type: actionTypes.REQUEST_ACHIEVEMENT_ERROR,
  error,
  taskId,
});

export const checkTask = taskId => {
  return (dispatch, getState) => {
    dispatch(requestAchievement(taskId));

    const userToken = getState().user.token.token;

    createAchievement(userToken, taskId).then(() => {
      dispatch(requestAchievementSuccess(taskId));
    })
    .catch(error => {
      dispatch(requestAchievementSuccess(error, taskId));
    });
  };
};

export const requestDeleteAchievement = taskId => ({
  type: actionTypes.REQUEST_DELETE_ACHIEVEMENT,
  taskId,
});

export const requestDeleteAchievementSuccess = taskId => ({
  type: actionTypes.REQUEST_DELETE_ACHIEVEMENT_SUCCESS,
  taskId,
});

export const requestDeleteAchievementError = (error, taskId) => ({
  type: actionTypes.REQUEST_DELETE_ACHIEVEMENT_ERROR,
  error,
  taskId,
});

export const unCheckTask = taskId => {
  return (dispatch, getState) => {
    dispatch(requestDeleteAchievement(taskId));

    const userToken = getState().user.token.token;

    deleteAchievement(userToken, taskId).then(() => {
      dispatch(requestDeleteAchievementSuccess(taskId));
    })
    .catch(error => {
      dispatch(requestDeleteAchievementError(error, taskId));
    });
  };
};

export const requestCreateMission = () => ({
  type: actionTypes.REQUEST_CREATE_MISSION
});

export const requestCreateMissionSuccess = () => ({
  type: actionTypes.REQUEST_CREATE_MISSION_SUCCESS
});

export const requestCreateMissionError = error => ({
  type: actionTypes.REQUEST_CREATE_MISSION_ERROR,
  error
});

export const fetchCreateMission = (data) => {
  return (dispatch, getState) => {
    dispatch(requestCreateMission());

    const userToken = getState().user.token.token;

    createMission(userToken, data).then(() => {
      dispatch(requestCreateMissionSuccess());
    })
    .catch(error => {
      dispatch(requestCreateMissionError(error));
    });
  };
};

export const requestDeleteMission = missionId => ({
  type: actionTypes.REQUEST_DELETE_MISSION,
  missionId
});

export const requestDeleteMissionSuccess = missionId => ({
  type: actionTypes.REQUEST_DELETE_MISSION_SUCCESS,
  missionId
});

export const requestDeleteMissionError = (missionId, error) => ({
  type: actionTypes.REQUEST_DELETE_MISSION_ERROR,
  missionId,
  error
});

export const fetchDeleteMission = missionId => {
  return (dispatch, getState) => {
    dispatch(requestDeleteMission(missionId));

    const userToken = getState().user.token.token;

    deleteMission(userToken, missionId).then(() => {
      dispatch(requestDeleteMissionSuccess(missionId));
    })
    .catch(error => {
      dispatch(requestDeleteMissionError(missionId, error));
    });
  };
};

export const requestEditMission = () => ({
  type: actionTypes.REQUEST_EDIT_MISSION
});

export const requestEditMissionSuccess = () => ({
  type: actionTypes.REQUEST_EDIT_MISSION_SUCCESS
});

export const requestEditMissionError = error => ({
  type: actionTypes.REQUEST_EDIT_MISSION_ERROR,
  error
});

export const fetchEditMission = (newData, missionId) => {
  return (dispatch, getState) => {
    dispatch(requestEditMission());

    const userToken = getState().user.token.token;

    updateMission(userToken, missionId, newData).then(() => {
      dispatch(requestEditMissionSuccess());
    })
    .catch(error => {
      dispatch(requestEditMissionError(error));
    });
  };
};
