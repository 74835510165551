import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { fetchLogin } from '../../redux/actions/user';
import Page from '../../components/Page';
import TopSection from './TopSection';
import HowItWorksSection from './HowItWorksSection';
import SimulationSection from './SimulationSection';
import FaqSection from './FaqSection';
import FieldSection from './FieldSection';
import LastAppealSection from '../../components/LastAppealSection';

class HomePage extends Component {

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  render() {
    return (
      <Page pageTitle="Ideias Energia - Home">
        <TopSection />
        <HowItWorksSection />
        <SimulationSection />
        <FaqSection />
        <FieldSection />
        <LastAppealSection />
      </Page>
    );
  }
}

// const mapStateToProps = ({ user }) => ({
//   user
// });

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({
//     fetchLogin
//   }, dispatch);
// };

// export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
export default HomePage;
