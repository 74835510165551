import {
  doLogin,
  doSignup,
  createUser,
  updateUser,
} from '../../services/User';
import { push } from 'connected-react-router';
import { fetchClasses } from './classes';

export const actionTypes = {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  REQUEST_LOGIN_SUCCESS: 'REQUEST_LOGIN_SUCCESS',
  REQUEST_LOGIN_ERROR: 'REQUEST_LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  REQUEST_SIGNUP: 'REQUEST_SIGNUP',
  REQUEST_SIGNUP_SUCCESS: 'REQUEST_SIGNUP_SUCCESS',
  REQUEST_SIGNUP_ERROR: 'REQUEST_SIGNUP_ERROR',
  REQUEST_CREATE_USER: 'REQUEST_CREATE_USER',
  REQUEST_CREATE_USER_SUCCESS: 'REQUEST_CREATE_USER_SUCCESS',
  REQUEST_CREATE_USER_ERROR: 'REQUEST_CREATE_USER_ERROR',
  NEW_USER_UPDATE: 'NEW_USER_UPDATE',
  REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
  REQUEST_UPDATE_USER_SUCCESS: 'REQUEST_UPDATE_USER_SUCCESS',
  REQUEST_UPDATE_USER_ERROR: 'REQUEST_UPDATE_USER_ERROR',
};

export const requestLogin = () => ({
  type: actionTypes.REQUEST_LOGIN
});

export const requestLoginSuccess = data => ({
  type: actionTypes.REQUEST_LOGIN_SUCCESS,
  data: data
});

export const requestLoginError = error => ({
  type: actionTypes.REQUEST_LOGIN_ERROR,
  error
});

export const fetchLogin = (email, password) => {
  return dispatch => {
    dispatch(requestLogin());

    doLogin(email, password).then(({ data }) => {
      localStorage.setItem('loggedUser', JSON.stringify(data));

      dispatch(requestLoginSuccess(data));
      dispatch(fetchClasses(data.token.token));
      dispatch(push('/missions'));
    })
    .catch(error => {
      dispatch(requestLoginError(error));
    });
  };
};

export const logout = () => {
  return dispatch => {
    localStorage.removeItem('loggedUser');
    dispatch({ type: actionTypes.LOGOUT });
    dispatch(push('/'));
  };
};

export const requestSignup = () => ({
  type: actionTypes.REQUEST_SIGNUP,
});

export const requestSignupSuccess = () => ({
  type: actionTypes.REQUEST_SIGNUP_SUCCESS,
});

export const requestSignupError = error => ({
  type: actionTypes.REQUEST_SIGNUP_ERROR,
  error
});

export const fetchSignup = (registrationCode, email, password) => {
  return dispatch => {
    dispatch(requestSignup());

    doSignup(registrationCode, email, password).then(() => {
      dispatch(requestSignupSuccess());
      dispatch(push('/login'));
    })
    .catch(error => {
      dispatch(requestSignupError(error));
    });
  };
};

export const requestCreateUser = () => ({
  type: actionTypes.REQUEST_CREATE_USER
});

export const requestCreateUserSuccess = data => ({
  type: actionTypes.REQUEST_CREATE_USER_SUCCESS,
  data: data
});

export const requestCreateUserError = error => ({
  type: actionTypes.REQUEST_CREATE_USER_ERROR,
  error
});

export const fetchCreateUser = (token, newUser) => {
  return dispatch => {
    dispatch(requestCreateUser());

    createUser(token, newUser).then(({ data }) => {
      dispatch(requestCreateUserSuccess(data));
    })
    .catch(error => {
      dispatch(requestCreateUserError(error));
    });
  };
};

export const newUserUpdate = () => ({
  type: actionTypes.NEW_USER_UPDATE
});

export const requestUpdateUser = () => ({
  type: actionTypes.REQUEST_UPDATE_USER
});

export const requestUpdateUserSuccess = data => ({
  type: actionTypes.REQUEST_UPDATE_USER_SUCCESS,
  data: data
});

export const requestUpdateUserError = error => ({
  type: actionTypes.REQUEST_UPDATE_USER_ERROR,
  error
});

export const fetchUpdateUser = (token, newUserData) => {
  return (dispatch, getState) => {
    dispatch(requestUpdateUser());

    const userId = getState().user.user.id;

    updateUser(token, userId, newUserData).then(({ data }) => {
      let loggedUser = localStorage.getItem('loggedUser');

      loggedUser = JSON.parse(loggedUser);
      localStorage.setItem('loggedUser', JSON.stringify({
        ...loggedUser,
        user: {
          ...loggedUser.user,
          ...data,
        }
      }));

      dispatch(requestUpdateUserSuccess(data));
    })
    .catch(error => {
      dispatch(requestUpdateUserError(error));
    });
  };
};
