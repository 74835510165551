import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  ${({isPageBlock}) => isPageBlock && `
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  `}
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(0,0,0,.8);
  color: #fff;
  font-size: 13px;
  letter-spacing: 3px;
`;

const Loader = ({ isPageBlock }) => {
  return (
    <Wrapper isPageBlock={isPageBlock}>
      CARREGANDO...
    </Wrapper>
  );
}

export default Loader;
