import React from 'react';
import styled from 'styled-components';
import CenterContent from '../../../components/Page/CenterContent';
import { BlueButton } from '../../../components/Button';
import Question from './Question';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 32px 16px;
`;

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 32px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }
`;

const QuestionsWrapper = styled.div`
  margin-top: 64px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 48px;
  text-align: center;
`;

const questions = [
  {
    startsOpen: true,
    question: 'Precisa de algum investimento?',
    answer: 'NENHUM. Não precisa de investimento nenhum.',
  },
  {
    startsOpen: false,
    question: 'Eu preciso de espaço na minha loja/casa para colocar as placas?',
    answer: 'Não, colocamos as placas em fazendas nas zonas rurais e você usa a mesma fiação da concessionária para receber a sua energia, que é compensada em sua conta mensal.',
  },
  {
    startsOpen: false,
    question: 'É preciso alterar algo na estrutura da minha casa/loja?',
    answer: 'Não, nenhuma adaptação é necessária, pois você recebe a energia gerada pelos equipamentos que você aluga, no sistema de compensação em sua conta.',
  },
  {
    startsOpen: false,
    question: 'Ainda não entendi direito como funciona. Vocês alugam energia?',
    answer: 'Nós alugamos o uso de nossas placas solares nas fazendas de energia. A energia que VOCÊ gera é repassada por nós à concessionária. Você ajuda o meio-ambiente e ainda recebe um belo desconto.',
  },
  {
    startsOpen: false,
    question: 'Eu vou continuar precisando da minha concessionária?',
    answer: (<span>Sim, pois todo o sistema de distribuição de energia é da sua concessionária.<br /><br />
      Entretanto, nós tomamos conta de tudo para você. Este é o um dos serviços que estamos oferecendo a você. Faremos a gestão de sua conta de energia. Você trata conosco, nós trabalhamos com as concessionárias e com as usinas de geração de energia.</span>)
  },
  // {
  //   startsOpen: false,
  //   question: 'E alugar essas Placas é legal?',
  //   answer: (<span>
  //       SIIIM. Em 2015, a ANEEL lançou uma resolução que possibilitou ao consumidor ter mais controle da sua própria energia. A RN 482 :). E em 2016 outra que permitiu que isso fosse feito remotamente, A RN 687 :). Saiba mais sobre as resoluções neste link. (<a href="https://www.aneel.gov.br/geracao-distribuida" title="Aneel" target="_blank">https://www.aneel.gov.br/geracao-distribuida</a>)
  //     </span>),
  // },
];

const FaqSection = () => {
  return (
    <Wrapper>
      <CenterContent maxWidth="784px">
        <SectionTitle>Perguntas Frequentes</SectionTitle>
        <QuestionsWrapper>
          {questions && questions.map(({startsOpen, question, answer}) => (
              <Question key={question} startsOpen={startsOpen} question={question}>
                {answer}
              </Question>
            ))}
        </QuestionsWrapper>
        <ButtonWrapper>
          <BlueButton as="a" href="/IdeiasEnergia-FAQ_2019-11-22.pdf" title="Ver todas as perguntas" target="_blank">Baixar FAQ completo (.pdf)</BlueButton>
        </ButtonWrapper>
      </CenterContent>
    </Wrapper>
  );
}

export default FaqSection;
