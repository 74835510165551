import styled from 'styled-components';

export const Message = styled.div`
  display: block;
  margin: 16px 0;
  padding: 8px;
  overflow: hidden;
  border-radius: 5px;
  background-color: rgba(255, 239, 56, 0.15);
  border: 1px solid #fcff1e;
  color: #222;
  font-size: .9rem;
`;

export const MessageSuccess = styled(Message)`
  background-color: rgba(6,213,115,.2);
  border: 1px solid #2ED573;
  color: #222;
`;

export const MessageError = styled(Message)`
  background-color: rgba(255,71,87,.1);
  border: 1px solid #ff4757;
  color: #222;
`;
