import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '../../components/Grid';

const Wrapper = styled.section`
  margin-bottom: 32px;
`;

const GroupTitle = styled.h2`
  display: inline-block;
  position: relative;
  font-size: 2rem;
  font-weight: 900;
  color: #3463DB;
  opacity: 0.4;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 55px);
    bottom: -12px;
    width: 110px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }
`;

const VisionTitle = styled(GroupTitle)`
  color: #FABC3C;
  margin-bottom: 16px;

  &:after {
    left: calc(50% - 38px);
    width: 76px;
  }
`;

const MissionTitle = styled(GroupTitle)`
  color: #2DD7A4;
  margin-bottom: 16px;

  &:after {
    left: calc(50% - 50px);
    width: 100px;
  }
`;

const Topic = styled.h3`
  margin-bottom: -8px;
  font-weight: 700;
  font-size: 1rem;
`;

const ValuesSection = () => {
  return (
    <Wrapper>
      <Row>
        <Col>
          <MissionTitle>Missão</MissionTitle>
          <p style={{fontSize: '1.15rem'}}>Empoderar todos na sua busca por sustentabilidade energética.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <VisionTitle>Visão</VisionTitle>
          <p style={{fontSize: '1.15rem'}}>Ser referência, de classe mundial, em soluções integradas e inovadoras no segmento de energia sustentável.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <GroupTitle>Valores</GroupTitle>
          </Row>
          <Row gutter="16px">
            <Col minWidth="300px">
              <Topic>Sustentabilidade</Topic>
              <p>Contribuir sempre para a melhoria do meio ambiente.</p>
              <Topic>Inovação</Topic>
              <p>Buscar sempre pela excelência tecnológica e por tecnologia disruptiva.</p>
              <Topic>+ Legal</Topic>
              <p>Estar sempre em adequação ao cenário regulatório e apoiar as estruturas regulatórias e associações no seu trabalho pelo bem do mercado.</p>
              <Topic>Visão Cliente</Topic>
              <p>Focar em ter clientes satisfeitos e parceiros de longo prazo</p>
            </Col>
            <Col minWidth="300px">
              <Topic>Integridade</Topic>
              <p>Ter sempre transparência, respeito e ética nos relacionamentos com clientes, parceiros e colaboradores</p>
              <Topic>Diversidade</Topic>
              <p>Buscar um ambiente diverso que incentive sempre a troca de conhecimento e o encontro de soluções criativas.</p>
              <Topic>Time nota 10</Topic>
              <p>Buscar sempre pelos melhores profissionais. Retendo os melhores talentos trazemos a excelência para dentro.</p>
              <Topic>Data +</Topic>
              <p>Tomar decisões embasadas em dados. </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default ValuesSection;
