import { actionTypes } from '../actions/missions';

const initialState = {
  hasLoaded: false,
  isLoading: false,
  hasError: false,
  actionFetching: {
    hasLoaded: false,
    isLoading: false,
    hasError: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_FETCHING_STATUS:
      return {
        ...state,
        hasLoaded: false,
        isLoading: false,
        hasError: false,
      }
    case actionTypes.RESET_ACTION_FETCHING_STATUS:
      return {
        ...state,
        actionFetching: {
          hasLoaded: false,
          isLoading: false,
          hasError: false,
        }
      }
    case actionTypes.REQUEST_MISSIONS:
      return {
        ...state,
        hasLoaded: false,
        isLoading: true,
      }
    case actionTypes.REQUEST_MISSIONS_SUCCESS:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: false,
        ...action.data,
      }
    case actionTypes.REQUEST_MISSIONS_ERROR:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: action.error,
      }
    case actionTypes.REQUEST_MISSION:
      return {
        ...state,
        hasLoaded: false,
        isLoading: true,
        selectedMission: null,
      }
    case actionTypes.REQUEST_MISSION_SUCCESS:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: false,
        selectedMission: action.mission,
        tasksById: action.tasksById,
      }
    case actionTypes.REQUEST_MISSION_ERROR:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: action.error,
      }
    case actionTypes.REQUEST_ACHIEVEMENT:
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.taskId]: {
            ...state.tasksById[action.taskId],
            isLoading: true,
          }
        }
      }
    case actionTypes.REQUEST_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.taskId]: {
            ...state.tasksById[action.taskId],
            isLoading: false,
            hasError: false,
            isDone: true,
          }
        }
      }
    case actionTypes.REQUEST_ACHIEVEMENT_ERROR:
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.taskId]: {
            ...state.tasksById[action.taskId],
            isLoading: false,
            hasError: action.error,
          }
        }
      }
    case actionTypes.REQUEST_DELETE_ACHIEVEMENT:
        return {
          ...state,
          tasksById: {
            ...state.tasksById,
            [action.taskId]: {
              ...state.tasksById[action.taskId],
              isLoading: true,
            }
          }
        }
      case actionTypes.REQUEST_DELETE_ACHIEVEMENT_SUCCESS:
        return {
          ...state,
          tasksById: {
            ...state.tasksById,
            [action.taskId]: {
              ...state.tasksById[action.taskId],
              isLoading: false,
              hasError: false,
              isDone: false,
            }
          }
        }
      case actionTypes.REQUEST_DELETE_ACHIEVEMENT_ERROR:
        return {
          ...state,
          tasksById: {
            ...state.tasksById,
            [action.taskId]: {
              ...state.tasksById[action.taskId],
              isLoading: false,
              hasError: action.error,
            }
          }
        }
      case actionTypes.REQUEST_CREATE_MISSION:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            isLoading: true,
          }
        }
      case actionTypes.REQUEST_CREATE_MISSION_SUCCESS:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: false,
          }
        }
      case actionTypes.REQUEST_CREATE_MISSION_ERROR:
        return {
          ...state,
          actionFetching: {
            ...state.actionFetching,
            hasLoaded: true,
            isLoading: false,
            hasError: action.error,
          }
        }
      case actionTypes.REQUEST_EDIT_MISSION:
          return {
            ...state,
            actionFetching: {
              ...state.actionFetching,
              isLoading: true,
            }
          }
        case actionTypes.REQUEST_EDIT_MISSION_SUCCESS:
          return {
            ...state,
            actionFetching: {
              ...state.actionFetching,
              hasLoaded: true,
              isLoading: false,
              hasError: false,
            }
          }
        case actionTypes.REQUEST_EDIT_MISSION_ERROR:
          return {
            ...state,
            actionFetching: {
              ...state.actionFetching,
              hasLoaded: true,
              isLoading: false,
              hasError: action.error,
            }
          }
      case actionTypes.REQUEST_DELETE_MISSION:
        return {
          ...state,
          missionsById: {
            ...state.missionsById,
            [action.missionId]: {
              ...state.missionsById[action.missionId],
              isLoading: true,
            }
          }
        }
      case actionTypes.REQUEST_DELETE_MISSION_SUCCESS:
        const { [action.missionId]: value, ...restMissions } = state.missionsById;

        return {
          ...state,
          missionsById: {
            ...restMissions
          }
        }
      case actionTypes.REQUEST_DELETE_MISSION_ERROR:
        return {
          ...state,
          missionsById: {
            ...state.missionsById,
            [action.missionId]: {
              ...state.missionsById[action.missionId],
              isLoading: false,
              hasLoaded: true,
              hasError: action.error,
            }
          }
        }
    default:
      return state
  }
}
