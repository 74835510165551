import styled from 'styled-components';
import { Row, Col } from '../../components/Grid';
import Button from '../../components/Button';

export const Wrapper = styled(Row)`
padding-top: 16px;

@media(max-width: 768px) {
  flex-direction: column-reverse;

  ${Col} {
    flex-basis: 100%;
    max-width: 100%;
  }
}
`;

export const PageLimit = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const Toolbar = styled.section`
  border-top: 1px solid #E3E4FF;
  border-bottom: 1px solid #E3E4FF;
  display: flex;
  padding: 16px;
  margin-top: 16px;

  h2 {
    display: inline;
    margin: 0;
    padding: 0;
  }

  ${PageLimit} {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const ActionsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MeterCard = styled.div`
  width: 200px;
  border-bottom: ${({ color }) => `5px solid ${color}`}
  background-color: #f5f5f5;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
  padding: 0 12px;
  padding-top: 6px;
  margin: 20px;
`;

export const Meters = styled.section``;

export const Bold = styled.div`
  margin-right: 30px;
  font-weight: bold;
`;

export const Ball = styled.div`
  position: relative;
  background-color: ${({ color }) => color || '#92D487'};
  width: 30px;
  height: 30px;
  border-radius: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    width: 100px;
    height: 1px;
    background-color: ${({ withLine }) => withLine ? '#2883EF' : 'transparent'};
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 130px;
`;

export const StepText = styled.div`
  text-align: center;
  margin-top: 8px;
  max-width: 85px;
`;

export const LastWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 16px 72px;
  background: linear-gradient(#3244da, #3ba0da);
  color: #fff;
`;

export const Title = styled.h1`
  position: relative;
  font-size: 2.4rem;
  text-align: center;
  margin: 0 0 32px;
`;

export const Ctas = styled.div`
  margin-top: 16px;
  text-align: center;

  ${Button} {
    margin: 8px;
    width: 260px;
    justify-content: center;
  }
`;
