import React from 'react'
import { Formik, Form, Field } from 'formik';
import Page from '../../components/Page';
import CenterContent from '../../components/Page/CenterContent';

import {
  FormCard,
  Tabs,
  TabButton,
  FormWrapper,
  SubmitButton,
} from './styles'

const defaultCredentials = { email: '', pwd: '', confirmPwd: '' }

const LoginPage = () => {
  const [variant, setVariant] = React.useState('login');
  const [credentials, setCredentials] = React.useState(defaultCredentials)

  const handleFieldChange = ({ target }) => {
    setCredentials((s) => ({
      ...s,
      [target.name]: target.value,
    }))
  }

  const handleSubmit = () => {
    // TODO: from here it should be called an action to redux and an effect to the API to login/signup the user
    console.log(credentials)
  }

  return (
    <Page pageTitle="Ideias Energia - Login">
      <CenterContent>
        <FormCard isMobile={window.innerWidth <= 600}>
          <Tabs>
            <TabButton
              isSelected={variant === 'login'}
              onClick={() => setVariant('login')}
            >
              Login
            </TabButton>

            <TabButton
              isSelected={variant === 'signup'}
              onClick={() => setVariant('signup')}
            >
              Signup
            </TabButton>
          </Tabs>

          <FormWrapper>
            <Formik
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              <Form>
                {variant === 'signup' && (
                  <>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Nome Completo"
                      onChange={handleFieldChange}
                    />

                    <Field
                      type="text"
                      name="cpf"
                      placeholder="CPF"
                      onChange={handleFieldChange}
                    />
                  </>
                )}

                <Field
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  onChange={handleFieldChange}
                />

                {variant === 'signup' && (
                  <>
                    <Field
                      type="phone"
                      name="phone"
                      placeholder="Phone Number"
                      onChange={handleFieldChange}
                    />

                    <Field
                      type="text"
                      name="sex"
                      placeholder="Sexo"
                      onChange={handleFieldChange}
                    />

                    <Field
                      type="text"
                      name="birthday"
                      placeholder="Aniversário"
                      onChange={handleFieldChange}
                    />
                  </>
                )}

                <Field
                  type="password"
                  name="pwd"
                  placeholder="password"
                  onChange={handleFieldChange}
                />

                {variant === 'signup' && (
                  <>
                    <Field
                      type="password"
                      name="confirmPwd"
                      placeholder="repetir password"
                      onChange={handleFieldChange}
                    />
                  </>
                )}

                <SubmitButton type="submit">
                  {variant === 'signup' ? 'Signup' : 'Login'}
                </SubmitButton>
              </Form>
            </Formik>
          </FormWrapper>
        </FormCard>
      </CenterContent>
    </Page>
  )
}

export default LoginPage
