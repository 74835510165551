
import styled from 'styled-components';

export const Col = styled.div`
  flex: ${({flex}) => flex || '1'};
  width: ${({width}) => width || 'auto'};
  min-width: ${({minWidth}) => minWidth || 'auto'};
  ${({centerHorizontally}) => centerHorizontally && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `};

  ${({centerVertically}) => centerVertically && `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: ${({flexWrap}) => flexWrap || 'nowrap'};
  justify-content: ${({justifyContent}) => justifyContent || 'unset'};

  @media (max-width: 768px) {
    flex-wrap: ${({flexWrap}) => flexWrap || 'wrap'};
  }

  ${({gutter}) => gutter && `
    transform: translateX(-${gutter});

    ${Col} {
      padding: 0 ${gutter};
    }
  `};
`;
