import styled from 'styled-components';

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 64px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }

  @media (max-width: 768px) {
    margin: 0 0 32px;
  }
`;

export default SectionTitle;
