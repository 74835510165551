import React from 'react';
import styled from 'styled-components';
import arrowIcon from './arrow-icon.png';

const Wrapper = styled.div`
  margin-bottom: 28px;
`;

const Question = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  padding-right: 32px;
  margin: ${({isOpen}) => isOpen ? '0 0 20px' : '0'};
  cursor: pointer;

  &:after {
    position: absolute;
    top: 12px;
    right: 4px;
    content: '';
    width: 12px;
    height: 9px;
    background: url(${arrowIcon}) no-repeat center;
    transform: ${({isOpen}) => isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Answer = styled.p`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  margin: 0;
  font-size: 1rem;
  color: #424242;

  @media (max-width: 768px) {
    font-size: .9rem;
  }
`;

class FaqSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.startsOpen || false,
    };
  }

  handleClickQuestion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {question, children} = this.props;

    return (
      <Wrapper>
          <Question
            isOpen={this.state.isOpen}
            onClick={this.handleClickQuestion}
          >
            {question}
          </Question>
          <Answer isOpen={this.state.isOpen}>{children}</Answer>
      </Wrapper>
    );
  }
}

export default FaqSection;
