import styled from 'styled-components';

const Button = styled.button`
  position: relative;
  display: inline-flex;
  padding: 0 15px;
  height: 40px;
  align-items: center;
  border-radius: 4px;
  /* background-color: #d4ed72; */
  background-color: ${({ bgColor }) => bgColor || '#92D487'};
  color: #162b48;
  font-weight: 500;
  font-size: .9rem;
  text-align: center;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: 200ms all ease-out;

  &:hover {
    transform: scale(1.05);
    text-decoration: none;
  }

  &:disabled {
    opacity: .6;
    cursor: wait;
  }
`;

export const WhiteSecondaryButton = styled(Button)`
  color: #fff;
  background: none;
  border: 1px solid #fff;
`;

export const BlueButton = styled(Button)`
  color: #fff;
  background: #2883EF;
`;

export default Button;
