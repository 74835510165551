import React from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { logout } from '../../../redux/actions/user';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  display: block;
  width: 100vw;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  background-color: rgba(23, 62, 109, 0.35);
  animation: ${fadeIn} 200ms linear;
`;

const Dropdown = styled.ul`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  padding: 32px 0;
  background-color: #fff;
  list-style: none;
  margin: -2px 0 0;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 59px);
  border: 0;
  border-radius: 0;
  box-shadow: inset 0 3px 8px -4px rgba(0,0,0,.2);

  /* &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: -9px;
    right: 20px;
    border-radius: 3px;
    border-left: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    transform: rotate(135deg);
    background-color: #fff;
  } */

  li {
    /* border-bottom: 2px solid #ddd; */

    &:last-child {
      border: 0;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
    }

    a {
      display: block;
      padding: 10px;
      /* font-weight: bold; */
      transition: all 150ms ease-out;

      text-align: center;
      font-size: 2rem;
      padding: 17px 16px;

      &:hover {
        background-color: #f1f2f6;
      }
    }
  }
`;

// const Topic = styled.span`
//   display: block;
//   padding: 20px 10px 10px;
//   font-size: .8rem;
//   text-transform: uppercase;
//   font-weight: 900;
//   color: #57606f;
// `;

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  showMenu = () => {
    this.setState({
      isOpen: true
    });
  }

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  hideMenu = () => {
    this.setState({
      isOpen: false
    });
  }

  // managementMenu = (userRoles) => {
  //   const hasHrManagerRole = userRoles.includes('hr_manager');
  //   const hasAdminRole = userRoles.includes('admin');

  //   if(hasHrManagerRole || hasAdminRole) {
  //     return (
  //       <React.Fragment>
  //         <li><Topic>Management</Topic></li>
  //         <li><Link to="/admin/create-user">Criar usuário</Link></li>
  //         <li><Link to="/admin/missions">Missões</Link></li>
  //       </React.Fragment>
  //     );
  //   }
  // }

  doLogout = e => {
    e.preventDefault();

    this.props.logout();
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return (
      <Wrapper
        // onMouseEnter={this.showMenu}
        // onMouseLeave={this.hideMenu}
        onClick={this.handleClick}
      >
        {children}
        {isOpen && <Backdrop onClick={this.hideMenu} />}
        <Dropdown isOpen={isOpen}>
          <li><Link to="/">Home</Link></li>
          {/* <li><Link to="/">Serviços</Link></li> */}
          {/* <li><Link to="/quem-somos">Quem somos</Link></li> */}
          <li><Link to="/contato">Contato</Link></li>
          <li><HashLink smooth to="/#simulacao">Fazer Simulação</HashLink></li>
          {/* <li><Link to="/edit-profile">Editar meu perfil</Link></li>
          <li><a href="#" title="Sair" onClick={this.doLogout}>Sair</a></li> */}
          {/* { this.managementMenu(userRoles) } */}
        </Dropdown>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    logout,
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(DropdownMenu);
