import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 60px;
  background: rgba(255,255,255, 0);
  top: ${({top}) => top || '0'};
  left: ${({left}) => left || '0'};
  cursor: pointer;
  transition: all 150ms ease-out;

  &:hover {
    width: 30px;
    height: 30px;
    background: rgba(255,255,255, .2);
  }
`;

const PopUp = styled.div`
  display: none;
  position: absolute;
  width: 204px;
  top: 16px;
  right: calc(100% - 2px);
  /* margin: 8px 0 0 35px; */
  padding: 16px;
  background: #fff;
  border-radius: 8px 0 8px 8px;
  border: 1px solid rgba(40, 131, 239, 0.3);
  color: #3a3a3a;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
  text-align: left;
  cursor: auto;

  ${({isOpen}) => isOpen && `
    display: block;
  `}
`;

const State = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 16px;
`;

const Project = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 300;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 8px 0;
  transition: all 100ms ease-out;

  &:last-child {
    border: 0;
  }
  &:hover {
    background-color: #f3f3f3;
    font-weight: 700;
    padding: 8px 4px;
  }

  em {
    float: right;
    font-style: normal;
  }
`;

class Pin  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  handleMouseOver = () => {
    this.setState({
      isOpen: true,
    })
  }

  handleMouseLeave = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { top, left, state, projects } = this.props;

    return (
      <Wrapper top={top} left={left} onMouseOver={this.handleMouseOver} onMouseLeave={this.handleMouseLeave}>
        <PopUp isOpen={this.state.isOpen}>
          <State>{state}</State>
          {projects && projects.map(({name, potency}) => (<Project key={name}>{name} <em>{potency}</em></Project>))}
        </PopUp>
      </Wrapper>
    );
  }
}

export default Pin;
