import { actionTypes } from '../actions/classes';

const initialState = {
  hasLoaded: false,
  isLoading: false,
  hasError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_CLASSES:
      return {
        ...state,
        hasLoaded: false,
        isLoading: true,
      }
    case actionTypes.REQUEST_CLASSES_SUCCESS:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: false,
        ...action.data,
      }
    case actionTypes.REQUEST_CLASSES_ERROR:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        hasError: action.error,
      }
    default:
      return state
  }
}
