import React from 'react';
import styled from 'styled-components';
import CenterContent from '../../../components/Page/CenterContent';
import { Row, Col } from '../../../components/Grid';
import shapeOne from './shape1.png';
import ilustTwo from './illust2-3.png';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 32px 16px;

  @media (max-width: 768px) {
    ${Col} {
      flex-basis: 100%;
    }
  }
`;

const SectionTitle = styled.h1`
  position: relative;
  margin: 0 0 64px;
  font-size: 2.5rem;
  text-align: center;
  color: #1E2036;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 135px);
    bottom: -12px;
    width: 270px;
    height: 1px;
    background-image: linear-gradient(to right,#1E2036 30%,rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
  }

  @media (max-width: 768px) {
    margin: 0 0 32px;
  }
`;

const BenefitTitle = styled.h2`
  position: relative;
  font-size: 2rem;
  font-weight: 900;
  color: #4C90DF;

  @media (max-width: 768px) {
    /* font-size: 1.5rem; */
    text-align: center;
  }
`;

const IllustrationOne = styled.figure`
  margin: 16px 0 0;

  img {
    max-width: 100%;
  }
`;

const IllustrationTwo = styled.figure`
  margin: 32px 0 0;

  img {
    transform: translateX(58px);
    max-width: 100%;

    @media (max-width: 768px) {
      transform: translateX(0);
    }
  }

`;

const StepsWrapper = styled(Row)`
  margin: 32px 0;
  min-height: 170px;

  ${Col} {
    position: relative;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
    padding: 0 32px;

    ${Col} {
      min-width: 100%;
    }
  }
`;

const First = styled.span`
  font-size: 92px;
  font-weight: 900;
  color: #2DD7A4;
  opacity: .4;

  @media (max-width: 768px) {
    font-size: 75px;
  }
`;

const Second = styled(First)`
  color: #FABC3C;
`;

const Third = styled(First)`
  color: #3463DB;
`;

const Step = styled.p`
  position: absolute;
  top: 75px;
  left: 52px;
  display: inline-block;
  margin: 0;
  padding-right: 24px;
  font-size: 1.2rem;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.2;
    top: 55px
  }
`;

const StepsTitle = styled(Row)`
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 56px 0 -16px;
  color: #ababab;
`;

const HowItWorksSection = () => {
  return (
    <Wrapper>
      <CenterContent>
        <SectionTitle>Como funciona</SectionTitle>
        <Row>
          <Col centerVertically>
            <BenefitTitle>Construímos usinas de energia limpa</BenefitTitle>
          </Col>
          <Col>
            <IllustrationOne>
              <img src={shapeOne} alt="Illustration" />
            </IllustrationOne>
          </Col>
        </Row>

        <StepsTitle>
          E você só precisa:
        </StepsTitle>

        <StepsWrapper>
          <Col>
            <First>1º</First>
            <Step>Informar a localização</Step>
          </Col>
          <Col>
            <Second>2º</Second>
            <Step>Alugar uma parcela da potência do projeto</Step>
          </Col>
          <Col>
            <Third>3º</Third>
            <Step>Levar sua energia para onde você for</Step>
          </Col>
        </StepsWrapper>

        <Row>
          <Col width="50%">
            <IllustrationTwo>
              <img src={ilustTwo} alt="Illustration 2" />
            </IllustrationTwo>
          </Col>
          <Col centerVertically>
            <BenefitTitle>Pronto, você cuida do seu negócio e nós cuidamos da sua energia!</BenefitTitle>
          </Col>
        </Row>
      </CenterContent>
    </Wrapper>
  );
}

export default HowItWorksSection;
